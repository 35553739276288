<!--suppress HtmlUnknownTag -->
<template>
    <div ref="fieldElement" v-bind="fieldAttrs" class="position-relative">

        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <div ref="formControlElement" v-bind="formControlAttrs">

            <div
                ref="shadowElement"
                class="shadow-input"
                :class="!filter?'placeholder':undefined"
                v-text="shadowText"
            />

            <aunoa-chip
                v-for="option in selectedOptions"
                :text="getTranslatedText(option)"
                :icon="getIcon(option)"
                :icon-style="getColoredIconStyle(option)"
                @delete="onDelete($event, option.value)"
            />

            <input ref="inputElement" v-bind="inputAttrs" v-model="filter" />

            <button ref="buttonElement" v-bind="buttonAttrs">
                <i v-bind="buttonIconAttrs" />
            </button>
            <aunoa-dropdown-menu
                class="dropdown-menu dropdown-menu-end experimental"
                tabindex="-1"
                style="cursor:default"
            />

        </div>
    </div>
</template>

<script lang="ts">

import type {LookupDetailedOption} from "../../implementations/lookup/useLookup";

import {useFieldValueCore, fieldProps} from "../../implementations/forms/useField";
import {lookupProps} from "../../implementations/lookup/useLookup";
import AunoaDropdownMenu from "./AunoaDropdownMenu.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";
import {useElementSize} from "../../utils/useElementSize";
import {useDropdown} from "../../utils/useDropdown";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import {computed, defineComponent, watch} from "vue";
import AunoaChip from "./AunoaChip.vue";

const UPDATE = "update:modelValue";

export default defineComponent({
    name: "AunoaMultiSelectBox",
    inheritAttrs: false,
    components: {AunoaDropdownMenu, AunoaChip, AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        ...lookupProps
    },
    emits: [UPDATE],
    setup(props, {attrs, emit}) {

        const field = useFieldValueCore(<any>props, attrs)
        const {disabled, readonly} = field;

        const dropdown = useDropdown(<any>props, {disabled, readonly, mode: "multi-select"});
        const {filter, selectedValues, getDisplay, ensureValue} = dropdown;

        const selectedOptions = computed(() => selectedValues.value.map(value => (<LookupDetailedOption>{value, display: getDisplay(value)})));
        const placeholder = computed(() => !selectedValues.value.length ? field.placeholder.value : undefined);
        const shadowText = computed(() => filter.value || placeholder.value || "#");
        const {element: shadowElement, offsetWidth: inputWidth} = useElementSize();

        watch(dropdown.selectedValues, values => {
            //console.log("selectedValues", values);
            // @ts-ignore
            if (!values.equals(field.value.value)) {
                field.value.value = values;
                emit(UPDATE, values);
            }
        }, {deep: true});

        watch(field.value, values => {
            values = (values || []).map(ensureValue);
            //console.log("values", values);
            if (!values.equals(selectedValues.value)) {
                selectedValues.value = values;
            }
        }, {immediate: true});


        const inputAttrs = computed(() => ({
            id: field.name.value,
            name: field.name.value,
            autocomplete: field.name.value,
            placeholder: placeholder.value,
            style: {
                width: inputWidth.value + 'px'
            },
        }));

        return {
            ...field,
            ...dropdown,
            placeholder,

            shadowElement,
            inputAttrs,
            shadowText,
            inputWidth,

            selectedOptions
        }
    }
});


</script>

