﻿// noinspection ES6PreferShortImport

import type {TextCasing} from "../../utils/textCasing";
import type {ComponentObjectPropsOptions, InjectionKey, PropType, Ref, ComputedRef} from "vue";

import {isArray, isBoolean, isDefined, isFunction, isNumber, isObject, isPromise, isString} from "../../utils/inspect";
import {createReplaceRegExp, createSearchOption, createSearchOptionStr, isStrMatching, markStr, SearchOption, SearchOptionStr} from "../../utils/search";
import {computed, inject, provide, ref, toRefs, watch} from "vue";
import {getValueFromPropertyPath} from "../../utils/properties";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {stringToHslStyles} from "../../utils/chips";
import {toCase} from "../../utils/textCasing";
import {useEntity} from "../useEntity";

// ############################################### types and interfaces

type Nullable<T> = T | null;

export interface LookupDetailedDisplay {
    text: Nullable<string>;
    icon?: Nullable<string>;
    country?: Nullable<string>;
    shortcut?: Nullable<string>;
    disabled?: boolean;
    deleted?: boolean;
    colorDeterminationText?: string;
}

export type LookupDisplay = string | number | LookupDetailedDisplay;

export interface LookupOption<TValue = any> {
    value: TValue;
    display: LookupDisplay;
}

export interface LookupDetailedOption<TValue = any> {
    value: TValue;
    display: LookupDetailedDisplay;
}

export interface LookupFilteredOption<TValue = any> {
    value: TValue;
    display: LookupDisplay | LookupDetailedDisplay;
}

export type FilterFunction<TValue = any> = (skip: number, take: number, include: string[], exclude: string[]) =>
    LookupFilteredOption<TValue>[] | Promise<LookupFilteredOption<TValue>[]>;

export interface Lookup<TEntity = any, TValue = any> {
    icon?: string;
    coloredIconBackground?: boolean;

    options?(entity: TEntity): LookupOption<TValue>[] | Promise<LookupOption<TValue>[]> | FilterFunction;

    resolve(value: TValue, entity: TEntity, ...args: any[]): LookupDisplay | Promise<LookupDisplay> | null;
}

export interface LookupFactory<TEntity = any, TValue = any> {
    name: string;

    create(): Lookup<TEntity, TValue>;
}

export type Lookups = Record<string, Lookup>;

export type LookupFactories = Record<string, LookupFactory>;

export type DataSource<TEntity = any, TValue = any> =
    string
    | Record<any, string | LookupDisplay>
    | Lookup<TEntity, TValue>
    | LookupFactory<TEntity, TValue>
    | LookupOption<TValue>[];

export type Expression = string | ((o: any) => any);

export interface LookupProps<TEntity = any, TValue = any> {
    dataSource: DataSource<TEntity, TValue>;
    valueExpression: Expression;
    displayExpression: Expression;
    colorDeterminationTextExpression: Expression;
    icon: string;
    coloredIconBackground: boolean;
    displayCasing: TextCasing;
}

export interface LookupUse<TValue = any> {
    lookup: Ref<Lookup<TValue>>;
    filter: Ref<string>;
    lookupError: Ref<string>;
    defaultIcon: Ref<string>;
    isBusy: Ref<boolean>;

    options: Ref<LookupDetailedOption<TValue>[]>;
    optionsDict: Ref<Record<any, LookupDetailedOption>>;

    optionsHaveIcons: Ref<boolean>;
    optionsHaveFlagIcons: Ref<boolean>;
    optionsHaveColors: Ref<boolean>;
    optionsAreBooleans: Ref<boolean>;

    setFilter: (value: string) => void;
    clearFilter: () => void;
    ensureValue: (value: TValue) => TValue;
    getOption: (value: TValue) => LookupDetailedOption;
    getDisplay: (value: TValue) => LookupDetailedDisplay;
    getIcon: (option: LookupDetailedOption) => any;
    getFlagIcon: (option: LookupDetailedOption) => any;
    getTranslatedText: (option: LookupDetailedOption) => string;
    getFilteredText: (option: LookupDetailedOption) => string;
    getColoredIconStyle: (option: LookupDetailedOption) => any;
    getTextFromOptionValue: (value: any) => string;

}


// ############################################### helper

export const isLookupFactory = (value: any): value is LookupFactory =>
    value && isString(value.name) && isFunction(value.create);

export const isLookup = (value: any): value is Lookup =>
    value && isFunction(value.resolve);

export const isLookupDetailedDisplay = (value: any): value is LookupDetailedDisplay =>
    value && isDefined(value.text);

const get = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression) ? expression(option) : option;

const get2 = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression) ? expression(option) : undefined;

const toArray = (options: any) => Object
    .entries(options)
    .map(([value, display]) => ({value, display})) as any[];

const ensureArray = (value: any): any[] =>
    isArray(value)
        ? value
        : isObject(value)
            ? toArray(value)
            : Array.from(value);

export const optionsToDict = (options: LookupOption[]) => options.reduce((dict, option) => {
    dict[option.value] = <LookupDetailedOption>{
        value: option.value,
        display: <LookupDetailedDisplay>option.display
    };
    return dict;
}, <Record<string, LookupDetailedOption>>{});

export const getLookupText = (display: LookupDisplay) =>
    isLookupDetailedDisplay(display)
        ? display.text
        : display?.toString();

// ############################################### lookup factories

const INJECTION_KEY: InjectionKey<Ref<LookupFactories>> = Symbol();

export const provideLookupFactories = (lookupFactories: Ref<LookupFactories | undefined>) => {

    provide(INJECTION_KEY, lookupFactories);
}

export const useLookupFactories = () => {

    const lookupFactories = inject(INJECTION_KEY, ref<LookupFactories>({}));

    const createLookup = <TEntity = any, TValue = any>(name: string) => {
        const factory = lookupFactories.value[name] as LookupFactory<TEntity, TValue>;
        return factory?.create();
    }

    return {
        createLookup
    }
}


// ############################################### lookup

export const lookupProps: ComponentObjectPropsOptions<LookupProps> = {
    dataSource: {
        type: [String, Object, Array] as PropType<DataSource>,
        default: undefined,
        required: true
    },
    valueExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.value
    },
    displayExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.display
    },
    colorDeterminationTextExpression: {
        type: [String, Function] as PropType<Expression>,
        default: undefined
    },
    icon: {
        type: String,
        default: undefined
    },
    coloredIconBackground: {
        type: Boolean,
        default: undefined
    },
    displayCasing: {
        type: String as PropType<TextCasing>,
        default: undefined
    }
}

export const useLookup = <TEntity = any, TValue = any>(props: LookupProps) => {

    const {dataSource} = toRefs(props);

    const filter = ref("");
    const lookupError = ref<string>();
    const lookup = ref<Lookup<TEntity, TValue>>();

    const defaultIcon = computed(() => props.icon || lookup.value?.icon);

    const {entity} = useEntity({});
    const {createLookup} = useLookupFactories();
    const {ensureTextTranslated} = useAunoaI18n();

    const valueOf = (option: LookupOption<TValue>) => get(option, props.valueExpression) as TValue;
    const displayOf = (option: LookupOption<TValue>) => get(option, props.displayExpression) as LookupDisplay;
    const colorDeterminationTextOf = (option: LookupOption<TValue>) => get2(option, props.colorDeterminationTextExpression) as string;

    const coloredIconBackground = () => isDefined(props.coloredIconBackground)
        ? props.coloredIconBackground
        : !!lookup.value?.coloredIconBackground;

    const toDisplayCase = (text: any) => text && props.displayCasing
        ? toCase(text, props.displayCasing)
        : text;

    //const getDisplay = (o: any) => toDisplayCase(get(o, displayExpression.value)) as unknown as any;

    const ensureDetailedDisplay = (display: LookupDisplay, colorDeterminationText?: string): LookupDetailedDisplay => {
        const detailed = (isLookupDetailedDisplay(display)
            ? display
            : isNumber(display)
                ? {text: display} // may we have to convert to string using culture
                : {text: toDisplayCase(display)}) as LookupDetailedDisplay;
        detailed.colorDeterminationText = detailed.colorDeterminationText || colorDeterminationText;
        return detailed;
    }

    const createLookupDetailedOption = (option: LookupOption<TValue>): LookupDetailedOption<TValue> => ({
        value: valueOf(option),
        display: ensureDetailedDisplay(displayOf(option), colorDeterminationTextOf(option))
    })

    watch(dataSource, value => {
        lookupError.value = undefined;
        if (isString(value)) {
            lookup.value = createLookup(value);
            if (!lookup.value) {
                lookupError.value = `Named Lookup '${value}' not found or lookup factory is invalid or missing`;
            }
        } else if (isLookupFactory(value)) {
            lookup.value = value.create();
        } else if (isLookup(value)) {
            lookup.value = value;
        } else if (isArray(value)) {
            const options = (<[]>value).map(o => isArray(o) ? {value: o[0], display: {text: o[1], icon: o[2]}} : o) as LookupDetailedOption<TValue>[];
            lookup.value = {
                options: e => options,
                resolve: (v, e, args) => options.filter(option => valueOf(option) == v).map(displayOf)[0],
            }
        } else if (isObject(value)) {
            const options = toArray(value);
            lookup.value = {
                options: e => options,
                resolve: (key, e, args) => (<any>value)[key] as any
            }
        } else {
            lookupError.value = `Lookup unknown or missing`;
            lookup.value = undefined;
        }
    }, {immediate: true})

    const busyCount: Ref<number> = ref(0);
    const isBusy: ComputedRef<boolean> = computed(() => busyCount.value > 0);

    const searchOption: ComputedRef<SearchOption> = computed(() => createSearchOption(filter.value));
    const searchOptionStr: ComputedRef<SearchOptionStr> = computed(() => createSearchOptionStr(filter.value));
    const replaceRegExp: ComputedRef<RegExp> = computed(() => createReplaceRegExp(searchOption.value));

    const filterFunc: Ref<FilterFunction<TValue> | undefined> = ref();
    const optionsSource: Ref<LookupDetailedOption<TValue>[]> = ref([]);

    const isSearchMatching = (option: LookupDetailedOption): boolean => {
        const text = getTranslatedText(option);
        return isStrMatching(text, searchOption.value);
    };

    const options: ComputedRef<LookupDetailedOption<TValue>[]> = computed(() =>
        filterFunc.value
            ? optionsSource.value
            : searchOption.value.include.length > 0
                ? optionsSource.value.filter(isSearchMatching)
                : optionsSource.value);

    const optionsDict: ComputedRef<Record<string, LookupDetailedOption>> = computed(() => optionsToDict(options.value));

    /*
        const options = computed<LookupDetailedOption<TValue>[]>(() =>
            lookup.value && lookup.value.options
                ? ensureArray(lookup.value.options(entity.value)).map(createLookupDetailedOption)
                : []);
    */


    const setOptionsSource = (value: any, max?: number) => {
        return optionsSource.value = ensureArray(value).map(createLookupDetailedOption);
    };

    watch([lookup], ([l]) => {
        if (l && l.options) {

            const result = l.options(entity.value);

            if (isFunction(result)) {
                filterFunc.value = result;
                setOptionsSource([]);
                return;
            }
            filterFunc.value = undefined;

            if (isPromise(result)) {
                busyCount.value++;
                result
                    .then(setOptionsSource)
                    .catch(error => {
                    })
                    .finally(() => busyCount.value--);
                return;
            }

            setOptionsSource(result);

        } else {
            setOptionsSource([]);
        }

    }, {immediate: true});

    watch([filterFunc, searchOptionStr], ([func, {include, exclude}]) => {
        if (func) {
            const max = 6;
            const result = func(0, max, include, exclude);

            if (isPromise(result)) {
                busyCount.value++;
                result
                    .then(data => setOptionsSource(data, max))
                    .catch(error => {
                    })
                    .finally(() => busyCount.value--);
                return;
            }

            setOptionsSource(result, max);
        }

    }, {immediate: true});


    const optionsHaveIcons = computed(() => !!defaultIcon.value ||
        options.value.some(option => isLookupDetailedDisplay(option.display) && option.display.icon));

    const optionsHaveFlagIcons = computed(() =>
        options.value.some(option => isLookupDetailedDisplay(option.display) && option.display.country));

    const optionsHaveColors = computed(() => coloredIconBackground());

    const optionsAreBooleans = computed(() =>
        options.value.length >= 2 &&
        options.value.length <= 3 &&
        isBoolean(options.value[0].value) &&
        isBoolean(options.value[1].value)
    );

    const setFilter = (value: string) =>
        filter.value = value;

    const clearFilter = () =>
        filter.value = "";

    const ensureValue = (value: TValue) =>
        optionsDict.value[<any>value]?.value || value;

    const getOption = (value: TValue) =>
        optionsDict.value[<any>value];

    const getDisplay = (value: TValue) =>
        lookup.value
            ? ensureDetailedDisplay(lookup.value.resolve(value, entity.value) as LookupDisplay)
            : undefined;

    const getIcon = (option: LookupDetailedOption) => {
        const icon = option && option.display && isLookupDetailedDisplay(option.display) && option.display.icon
            ? option.display.icon
            : undefined;
        return icon || defaultIcon.value || "far";
    };

    const getFlagIcon = (option: LookupDetailedOption) =>
        option && option.display && option.display.country && isLookupDetailedDisplay(option.display)
            ? `flag-icon-${option.display.country}`.toLowerCase()
            : undefined;

    const getDeletedIcon = (option: LookupDetailedOption) => {
        const icon = option && option.display && isLookupDetailedDisplay(option.display) && option.display.icon
            ? option.display.icon
            : undefined;
        return icon || defaultIcon.value || "far";
    };

    const getTranslatedText = (option: LookupDetailedOption) =>
        ensureTextTranslated(getLookupText(option?.display) || "") || option?.value || "";

    const getFilteredText = (option: LookupDetailedOption) =>
        markStr(getTranslatedText(option), replaceRegExp.value);

    const getColoredIconStyle = (option: LookupDetailedOption) =>
        option && option.display && optionsHaveColors.value
            ? stringToHslStyles(option.display.colorDeterminationText || option.display.text)
            : undefined;

    const getTextFromOptionValue = (value: any) =>
        getTranslatedText(optionsDict.value[value]);


    return <LookupUse>{
        lookup,
        filter,
        lookupError,
        defaultIcon,
        isBusy,

        options,
        optionsDict,

        optionsHaveIcons,
        optionsHaveFlagIcons,
        optionsHaveColors,
        optionsAreBooleans,

        setFilter,
        clearFilter,
        ensureValue,

        getOption,
        getDisplay,
        getIcon,
        getFlagIcon,
        getTranslatedText,
        getFilteredText,
        getColoredIconStyle,
        getTextFromOptionValue

    }

};