﻿import {useI18n} from "vue-i18n";

type T = (key: string | number) => string;


export const useLocalizationHelper = (initialT?:T) => {

    let t: T;

    const ensureT = () => {
        t = t || useI18n().t;
        return t;
    }

    const translatableIndex = (text: string): number =>
        text && text.indexOf ? text.indexOf("@@") : -1;


    const translateText = (t: T, text: string, index: number): string => {
        const key = text.substring(index + 2).replace("/", ".").replace(":", ".");
        const translated = t(key);
        return index === 0
            ? translated
            //: translated === key ? text.substr(0, index) : translated;
            //: translated === key ? "#"+text.substr(0, index) : translated;
            : translated === key ? text.substring(index) : translated;
    };

    const ensureTextTranslated = (text: string): string => {
        const index = translatableIndex(text);
        return index >= 0
            ? translateText(ensureT(), text, index)
            : text;
    };


    const ensureTextTranslated2 = (text: string, i18n:any): string => {
        const index = translatableIndex(text);
        return index >= 0
            ? translateText(i18n.t, text, index)
            : text;
    };


    const ensureTextTranslationWithFallback = (text:string, fallback: (t:string)=>string) => {
        const index = translatableIndex(text);
        return index >= 0 && initialT
            ? translateText(initialT, text, index)
            : fallback(text);
    }



    return {
        translatableIndex,
        translateText,
        ensureTextTranslated,
        ensureTextTranslated2,
        ensureTextTranslationWithFallback        
    }
};