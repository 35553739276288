﻿<!--suppress HtmlFormInputWithoutLabel, ES6PreferShortImport -->

<template>
    <div v-bind="fieldAttrs">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs">
                <span v-if="hasFocus && charInfo && !$attrs.readonly" v-text="charInfo" />
            </aunoa-form-label>
        </slot>
        <textarea v-bind="controlAttrs" v-if="rows>1" />
        <template v-else>
            <div v-bind="inputGroupAttrs" v-if="icon">
                <span class="input-group-text">
                    <i class="fa-fw fad" :class="icon" />
                </span>
                <input ref="inputElement" type="text" v-bind="controlAttrs" />
                <span @click="togglePassword" v-if="controlAttrs.hint" class="input-group-text field-icon">
                  <i class="fa-fw fad fa-eye" />
                </span>
            </div>
            <div v-bind="inputGroupAttrs" v-else-if="controlAttrs.hint">
                <input ref="inputElement" type="text" v-bind="controlAttrs" />
                <span @click="togglePassword" class="input-group-text field-icon">
                <i class="fa-fw fad fa-eye" />
            </span>
            </div>
            <input type="text" v-bind="controlAttrs" v-else />
        </template>
        <aunoa-error-message v-bind="errorMessageAttrs" />
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, watch, ref} from "vue";
import {fieldProps, useField} from "../../implementations/forms/useField";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";

export default defineComponent({
    name: "AunoaTextBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        modelValue: {
            type: String,
        },
        subType: {
            type: String
        },
        icon: {
            type: String
        },
        textCasing: {
            type: String
        },
        lines: {
            type: Number
        }
    },
    setup(props, ctx) {

        const inputElement = ref<HTMLInputElement | null>()
        const field = useField(props as any, ctx, {
            onInput: (e) => {
                const input = <HTMLInputElement>e.target;
                if (props.textCasing === "upper") {
                    input.value = input.value?.toUpperCase();
                } else if (props.textCasing === "lower") {
                    input.value = input.value?.toLowerCase();
                }
            },
        })

        const charInfo = computed(() => {
            const max = field.fieldValidation.value?.maxLength?.$params?.max;
            const length = field.value.value?.length;
            return max && length
                ? length + "/" + max
                : undefined;
        });

        const togglePassword = () => {
            if (inputElement.value) {
                if (inputElement.value.type === "password") {
                    inputElement.value.type = "text";
                } else {
                    inputElement.value.type = "password";
                }
            }
        };

        return {
            inputElement,
            togglePassword,
            charInfo,
            ...field
        }
    }
});

</script>
