﻿<!--suppress ES6PreferShortImport -->
<template>
    <component :is="as" v-bind="$attrs">
        <slot name="header" />
        <component
            v-if="groupModel.component"
            :is="groupModel.component"
            :entity="entity"
        />
        <template
            v-else
            v-for="rowOrField in groupModel.fields"
        >


            <component
                v-if="Array.isArray(rowOrField)"
                :is="rowAs"
                :class="rowClass">
                <div
                    :class="columnClass"
                    v-for="field in rowOrField"
                >
                    <aunoa-form-field
                        :field="field"
                        :field-class="fieldClass"
                    />
                </div>
            </component>
            <div v-else-if="rowOrField.array">

                <template v-for="(item,i) in getArrayItem(rowOrField.array)">
                    <template v-for="childField in rowOrField.fields">
                        <aunoa-form-field
                            :field="childField"
                            :field-class="fieldClass"
                            :property="getChildPath(rowOrField, i, childField.property)"
                            :label="getChildLabel(rowOrField, i, childField.label)"
                        />
                    </template>
                </template>
            </div>

            <aunoa-form-field
                v-else
                :field="rowOrField"
                :field-class="fieldClass"
            />


        </template>
        <slot name="footer" />
    </component>
</template>

<script lang="ts">

import {useEntity} from "../../implementations/useEntity";
import AunoaFormField from "./AunoaFormField.vue";
import AunoaTextBox from "./AunoaTextBox.vue";
import {computed, defineComponent, PropType} from "vue";
import {Forms} from "../../types";
import {getValueFromPropertyPath} from "../../utils/properties";
import {isString} from "../../utils/inspect";

export default defineComponent({
    name: "AunoaFormGroup",
    inheritAttrs: false,
    components: {
        AunoaFormField,
        AunoaTextBox
    },
    props: {
        as: {
            default: "div"
        },
        rowAs: {
            default: "div"
        },
        groupModel: {
            type: Object as PropType<Forms.Model.Group>
        },
        rowClass: {},
        columnClass: {},
        fieldClass: {}
    },
    setup(props) {

        const {entity} = useEntity();

        const getArrayItem = (path: string) =>
            getValueFromPropertyPath(entity.value, path);

        const getChildPath = (parent: Forms.Model.Field, index: number, property: string) =>
            `${parent.array}[${index}].${property}`;

        const getChildLabel = (parent: Forms.Model.Field, index: number, label: string) => {
            if (isString(label) && label.startsWith("{{") && label.endsWith("}}")) {
                label = label.substring(2, label.length - 2);
                const path = `${parent.array}[${index}].${label}`;
                return "»" + getValueFromPropertyPath(entity.value, path) + "«";
            }
            return label;
        };


        return {
            entity,
            getArrayItem,
            getChildPath,
            getChildLabel
        }

    }
});

</script>
