﻿import type {Ref, ComputedRef} from "vue";

export type AccessToken = Ref<string | undefined> | ComputedRef<string | undefined> | string | undefined | "ANONYMOUS";

export declare type ScopeID = "GLOBAL" | "COUNTRY" | "TENANT" | "LOCATION" | "TERMINAL";
export declare type TerminalScope = "ANY" | "POS" | "PAN";

export enum ScopeVisibility {
    Visible = 0,
    Disabled = 1,
    Deleted = 2
}

export interface Scope {
    type: number;
    id: ScopeID;
    name: string;
    bgClassName: string;

    getCountryCode(item: NullableScopeItem): string;
    getCountryText(item: NullableScopeItem): string;
    getLocationText(item: NullableScopeItem): string;
}

export interface ScopeItem {
    index: number;
    scope: Scope;
    oid: number | null;
    id: string | null;
    text: string | null;
    description: string | null;
    visibility: ScopeVisibility;
    parent: NullableScopeItem;
    children: ScopeItem[];

    countryCode(): string;
    countryFlag(): string;
    countryText(): string;
    locationText(): string;
}

export declare type NullableScopeItem = ScopeItem | null | undefined;

export declare type CompressedScopeItem = [
    number,
    number | null,
    string,
    number,
    number | null,
    string | null,
    string | null,
    number,
    number
];

export interface ScopeSources {
    scopesLoaded: Ref<boolean>;
    scopesLoading: Ref<boolean>;

    globals: Ref<ScopeItem[]>;
    countries: Ref<ScopeItem[]>;
    tenants: Ref<ScopeItem[]>;
    locations: Ref<ScopeItem[]>;
    terminals: Ref<ScopeItem[]>;

    updateScopeSources(accessToken: AccessToken): Promise<void>;

    getScopeItems(id: ScopeID): ScopeItem[];
    getScopeItem(id: ScopeID, index: number): ScopeItem;
    getTenant(tenantOid?: number): ScopeItem;
    getLocation(locationRoleOid?: number): ScopeItem;
    getTerminal(terminalOid?: number): ScopeItem;
    getLocations(maxVisibility:ScopeVisibility, tenantOid?: number): ScopeItem[];
    getTerminals(terminalScope : TerminalScope, locationRoleOid?: number): ScopeItem[];

    createTenantIdDict(): Record<string, number>;
    createTenantOidDict(): Record<number, string>;

}

export interface ScopeNavigation extends ScopeSources {
    navigationIndex: number;
    selectedScope: Ref<ScopeItem | undefined>;
    selectedScopeParents: Ref<ScopeItem[]>;
    selectedScopeChildren: Ref<ScopeItem[]>;
}

export interface ScopeNavigationFactory {
    create(): ScopeNavigation;
}