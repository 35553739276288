﻿import {App} from "vue";

// @ts-ignore
import jsonlint from "jsonlint-mod/web/jsonlint";


import {Navigation} from "./navigation";

import AunoaDropdownCheckItem from "./components/dropdown/AunoaDropdownCheckItem.vue";
import AunoaDropdownDivider from "./components/dropdown/AunoaDropdownDivider.vue";
import AunoaDropdownItem from "./components/dropdown/AunoaDropdownItem.vue";
import AunoaDropdownItemText from "./components/dropdown/AunoaDropdownItemText.vue";

// components 

// controls
//import AunoaDataGrid from "./controls/grid/AunoaDataGrid.vue";
//import AunoaDataGridInline from "./controls/grid/AunoaDataGridInline.vue";

import AunoaCheckboxTree from "./controls/AunoaCheckboxTree.vue";
import AunoaCommandBar from "./controls/AunoaCommandBar.vue";
import AunoaFooter from "./controls/AunoaFooter.vue";
import AunoaFormBox from "./controls/AunoaFormBox.vue";
import AunoaFormPane from "./controls/AunoaFormPane.vue";

import AunoaModalDialog from "./controls/AunoaModalDialog.vue";
import AunoaModalForm from "./controls/AunoaModalForm.vue";

import AunoaTitleBar from "./controls/AunoaTitleBar.vue";
import AunoaTabControl from "./controls/AunoaTabControl.vue";
import AunoaNavigationBar from "./controls/AunoaNavigationBar.vue";


import FocusDirective from "./directives/focus";
import StickyTopDirective from "./directives/stickyTop";

import AdminLayout from "./layouts/AdminLayout.vue";
import PlainLayout from "./layouts/PlainLayout.vue";

import AunoaSelectNavItemMixin from "./mixins/AunoaSelectNavItemMixin";
import {User} from "./mixins/UserMixin";

import {useBodyBackground} from "./utils/useBodyBackground";
import {useBodyScrollbar} from "./tools/bodyScrollbar";

import AunoaAuthLogin from "./views/AunoaAuthLogin.vue";
import AunoaForbidden403 from "./views/AunoaForbidden403.vue";
import AunoaNotFound404 from "./views/AunoaNotFound404.vue";

import FadeTransition from "./transitions/FadeTransition.vue";


export * from "./utils/json";
export * from "./utils/search";
export * from "./utils/useTestMode";
export * from "./utils/useAunoaI18n";
export * from "./utils/useIconAlias";
export * from "./utils/useDateTimeUtils";
export * from "./utils/useEventListener";
export * from "./utils/useTextAreaAutoSize";
export * from "./utils/useBusy";

export type {EntityModelOptions} from "./implementations/entityModel";
import {createEntityModel} from "./implementations/entityModel";

export type {DetailsModelOptions} from "./implementations/detailsModel";
import {createDetailsModel} from "./implementations/detailsModel";

export type {FormModelOptions} from "./implementations/formModel";
import {createFormModel, mergeFormModels} from "./implementations/formModel";

export type {GridModelOptions, GridModelContext} from "./implementations/gridModel";
import {createGridModel} from "./implementations/gridModel";

const modelBuilder = {
    CreateEntityModel: createEntityModel,
    CreateDetailsModel: createDetailsModel,
    CreateFormModel: createFormModel,
    CreateGridModel: createGridModel
}

export {
    createEntityModel,
    createDetailsModel,
    createFormModel,
    createGridModel,
    mergeFormModels,
    modelBuilder
}


export type {
    Entities, Tables, Forms, DataService, CrudReadOptions, CrudException, CrudError,
    PromisableEvent, ModalDialog, NamedConverter, BusyOptions, Decoration
} from "./types";


export type {
    LookupFactories, LookupFactory, Lookups, Lookup, LookupOption, LookupDisplay, LookupDetailedDisplay
} from "./implementations/lookup/useLookup";
export {provideLookupFactories, useLookupFactories/*, useLookup*/} from "./implementations/lookup/useLookup";

export {getCurrencyFormatSegments} from "./implementations/decoration/currencyAmountDecoration";
export {decorate} from "./implementations/decoration/decoration";


export {ensureResolvedPropertyPath, getValueFromPropertyPath} from "./utils/properties";
export * from "./utils/useConditionModel";


export {formatDate} from "./implementations/decoration/useDateDecoration";
export * from "./implementations/decoration/numberDecoration";

export * from "./implementations/decoration/currencyAmountDecoration";


export * from "./utils/entities";

export * from "./components";
export * from "./teleports";

export * from "./components/form/form";
export * from "./components/nav/nav";
export * from "./components/tab/tab";

export * from "./links";

export * from "./utils/storage/useLocalStorage";
export * from "./utils/storage/useSessionStorage";

export * from "./utils/clipboard";
export * from "./utils/useDebounce";
export * from "./utils/singletons";
export * from "./utils/helper";
export * from "./utils/inspect";
export * from "./utils/textCasing";
export * from "./utils/singletons";
export * from "./utils/useDropdownMenu";
export * from "./utils/useTypeahead";

export * from "./utils/useElementSize";
export * from "./utils/useAunoaOptions";
export {useLocale, useLocaleController, useI18nController} from "./utils/useLocale";
export * from "./utils/usePageOptions";
export {provideSorting} from "./utils/useSorting";

export {useFavoritePages} from "./utils/useFavoritePages";
export {useMostRecentlyUsedPages} from "./utils/useMostRecentlyUsedPages";
export {useToasts} from "./utils/useToasts";
export {usePageInfo} from "./utils/usePageInfo";
export {useClipboardData} from "./utils/useClipboardData";
export {stringToHslStyles, stringToHslStylesReversed} from "./utils/chips";
export {useDateDecoration} from "./implementations/decoration/useDateDecoration"

export {createPermissionTool, createTransferRoles, createRoles} from "./implementations/forms/usePermissions";


export * from "./utils/useAunoa";

export type {
    Navigation,
    User
}

export {
    // components / dropdown
    AunoaDropdownCheckItem,
    AunoaDropdownDivider,
    AunoaDropdownItem,
    AunoaDropdownItemText,

    // components 

    AunoaAuthLogin,

    // controls
    //AunoaDataGrid,
    //AunoaDataGridInline,
    AunoaCheckboxTree,
    AunoaCommandBar,
    AunoaFooter,
    AunoaFormBox,
    AunoaFormPane,
    AunoaModalDialog,
    AunoaModalForm,
    AunoaTitleBar,
    AunoaTabControl,
    AunoaNavigationBar,

    // directives
    StickyTopDirective,

    AdminLayout,
    PlainLayout,

    AunoaSelectNavItemMixin,

    useBodyBackground,
    useBodyScrollbar,

    AunoaForbidden403,
    AunoaNotFound404,

    FadeTransition
}


import {storageDefaultNamespace, locales, defaultLocale, fallbackLocale} from "./core";
import {installFormComponents, FormComponentOptions} from "./components/form/form";
import {installNavComponents, NavComponentOptions} from "./components/nav/nav";
import {installTabComponents, TabComponentOptions} from "./components/tab/tab";
import {useAunoaI18nController} from "./utils/useAunoaI18n";
import {FormModelOptions} from "./implementations/formModel";
import AunoaTextBox from "./components/form/AunoaTextBox.vue";
import {provideLocaleController} from "./utils/useLocale";
import AunoaForm from "./components/form/AunoaForm.vue";
import {useAunoa} from "./utils/useAunoa";
import {Composer} from "vue-i18n";
import {stringToHslStyles} from "./utils/chips";


export type  {ModalSize} from "./utils/useModal";


export interface BootstrapAunoaOptions {

    storageDefaultNamespace?: string;

    locales?: string[];
    defaultLocale?: string;
    fallbackLocale?: string;
    ensureTranslated?(text: string, i18n: Composer<any, any, any>): string;


    //localStorageNamespace?: string;
    registerAllComponents?: boolean;
    registerFormComponents?: boolean | FormComponentOptions;
    registerNavComponents?: boolean | NavComponentOptions;
    registerTabComponents?: boolean | TabComponentOptions;
}

// @ts-ignore
window["jsonlint"] = jsonlint;

function install(app: App, options?: BootstrapAunoaOptions) {
    options = options || {};

    storageDefaultNamespace.value = options.storageDefaultNamespace || storageDefaultNamespace.value;
    locales.value = options.locales || locales.value;
    defaultLocale.value = options.defaultLocale || defaultLocale.value;
    fallbackLocale.value = options.fallbackLocale || fallbackLocale.value;

    provideLocaleController(app, options.ensureTranslated || ((text, i18n) => text));

    useAunoa();

    //app
    //.component(AunoaHourglass.name, AunoaHourglass)
    //.component(Zucchetti.name, Zucchetti)
    //.component(ZucchettiLowRes.name, ZucchettiLowRes)
    //.component(AunoaLookupSpan.name, AunoaLookupSpan)
    //.component(AunoaScrollbar.name, AunoaScrollbar);

    const registerForm = options.registerFormComponents || options.registerAllComponents;
    const registerNav = options.registerNavComponents || options.registerAllComponents;
    const registerTab = options.registerTabComponents || options.registerAllComponents;

    registerForm && installFormComponents(app, {});
    registerNav && installNavComponents(app, {});
    registerTab && installTabComponents(app, {});

    if (registerTab) {
        app.component(AunoaTabControl.name, AunoaTabControl);
    }

    app.directive("sticky-top", StickyTopDirective);
    app.directive("focus", FocusDirective);

}

console.log("%c" + "...powered by Bootstrap Aunoa, Retail Enterprise Development Tap & Till, member of Zucchetti group...", "background-color:#0072BC;padding:10px 20px;margin:20px 0;color:#fff;font-size:15px;font-family:sans-serif;border: solid .25rem #f2a900")


// @ts-ignore
if (Array.prototype.equals) {
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
}
// attach the .equals method to Array's prototype to call it on any array
// @ts-ignore
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time 
    if (this.length != array.length)
        return false;

    for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        } else if (this[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});

// LGB, Allow empty catch functions:  .catch()
const originalCatch = Promise.prototype.catch;
Promise.prototype.catch = function (this: any) {
    //console.error('> > > > > > called .catch on %o with arguments: %o (%o)', this, arguments, arguments.length);
    return originalCatch.apply(this, arguments.length === 0 ?
        <any>[() => {
        }]
        : arguments);
};


export default {
    install
}


