﻿import {isArray, isDefined, isObject} from "./inspect";

declare type SearchQuery = string | undefined;

export interface SearchOption {
    include: RegExp[];
    exclude: RegExp[];
}

export interface SearchOptionStr {
    include: string[];
    exclude: string[];
}

const MARK_O = "\x02";
const MARK_C = "\x03";

const MARK_OPEN_TAG = "<mark>";
const MARK_CLOSE_TAG = "</mark>";

const regExpO = new RegExp(MARK_O, "g");
const regExpC = new RegExp(MARK_C, "g");
const regExpCO = new RegExp(MARK_C + MARK_O, "g");

const isSearchOption = (value: any): value is SearchOption => value && isArray(value.positive);

export const getSearchStrings = (searchQuery: SearchQuery): string[] => searchQuery
    ? searchQuery.split(" ").filter(s => s)
    : [];

export const markWithSearchQuery = (str: string, searchQuery: SearchQuery): string => {
    getSearchStrings(searchQuery)
        .forEach(searchString => {
            str = str.replace(new RegExp(searchString, "ig"), s => MARK_O + s + MARK_C);
        });
    return str
        .replace(regExpCO, "")
        .replace(regExpO, MARK_OPEN_TAG)
        .replace(regExpC, MARK_CLOSE_TAG);
};

export const createSearchOption = (searchQuery: SearchQuery): SearchOption => {
    const searchOption: SearchOption = {include: [], exclude: []}
    if (searchQuery?.length) {
        searchQuery.split(" ").forEach(str => {
            if (str.startsWith("-")) {
                str.length > 1 && searchOption.exclude.push(new RegExp(str.substring(1), "i"));
            } else if (str.startsWith("+")) {
                str.length > 1 && searchOption.include.push(new RegExp(str.substring(1), "i"));
            } else {
                searchOption.include.push(new RegExp(str, "i"));
            }
        });
    }
    return searchOption;
};

export const createSearchOptionStr = (searchQuery: SearchQuery): SearchOptionStr => {
    const searchOptionStr: SearchOptionStr = {include: [], exclude: []}
    if (searchQuery?.length) {
        searchQuery.split(" ").forEach(str => {
            if (str.startsWith("-")) {
                str.length > 1 && searchOptionStr.exclude.push(str.substring(1));
            } else if (str.startsWith("+")) {
                str.length > 1 && searchOptionStr.include.push(str.substring(1));
            } else {
                searchOptionStr.include.push(str);
            }
        });
    }
    return searchOptionStr;
};

export const isStrMatching = (str: string, searchOption: SearchOption): boolean => {
    const matches = (regexp: RegExp): boolean => str.match(regexp) as any;
    return ((!searchOption.exclude.some(matches)) && (searchOption.include.every(matches)));
};

export const createReplaceRegExp = (searchOption: SearchOption): RegExp => searchOption?.include?.length
    ? new RegExp(`(${searchOption.include.map(r => r.source).join("|")})`, "ig")
    : null as any as RegExp;

export const markStr = (str: string, param: (SearchOption | RegExp)): string => {
    if (isSearchOption(param)) {
        param = createReplaceRegExp(param);
    }
    if (param) {
        str = str
            .replace(param as RegExp, s => MARK_O + s + MARK_C)
            .replace(regExpCO, "")
            .replace(regExpO, MARK_OPEN_TAG)
            .replace(regExpC, MARK_CLOSE_TAG);
    }
    return str;
};
