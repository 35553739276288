﻿<!--suppress HtmlFormInputWithoutLabel, HtmlUnknownTag -->
<template>
    <div ref="fieldElement" v-bind="fieldAttrs">

        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <div v-bind="inputGroupAttrs">
            <div ref="formControlElement" v-bind="formControlAttrs" class="d-flex align-items-center">

                <div class="form-control-icon" v-if="optionsAreBooleans && optionsHaveIcons && iconClass" style="cursor: pointer"
                     @click.stop="onToggle">
                    <i class="fa-fw fa-lg" :class="iconClass" />
                </div>
                <div class="form-control-icon" v-else-if="optionsHaveIcons && optionsHaveColors && iconClass && displayIcon">
                    <i class="fa-fw custom-icon-rounded" :class="iconClass" :style="iconStyle" />
                </div>
                <div class="form-control-icon" v-else-if="optionsHaveIcons && iconClass && displayIcon">
                    <i class="fa-fw" :class="iconClass" />
                </div>
                <div class="form-control-icon" v-else-if="optionsHaveFlagIcons && iconClass && displayIcon">
                    <i class="flag-icon shadow-sm" :class="iconClass" />
                </div>

                <input ref="inputElement" v-bind="inputAttrs">

                <button ref="buttonElement" v-bind="buttonAttrs">
                    <i v-bind="buttonIconAttrs" class="fa-fw" />
                </button>
                <aunoa-dropdown-menu
                    class="dropdown-menu dropdown-menu-end experimental"
                    tabindex="-1"
                />

            </div>
        </div>

        <div class="small text-danger text-center" v-if="lookupError" role="alert">
            <i class="fas fa-arrow-up px-1"></i>
            <span v-text="lookupError" />
            <i class="fas fa-arrow-up px-1"></i>
        </div>

        <aunoa-error-message v-bind="errorMessageAttrs" />

    </div>
</template>

<!--
        <label v-if="label" v-bind="labelAttrs" v-text="`${label} (Value: ${value})`" />
        <input type="text" v-bind="controlAttrs" class="form-control-sm mb-1">
        
        <template v-if="0">
            <div>entity: {{JSON.stringify(entity)}}</div>
            <div>modelValue: {{JSON.stringify(value)}}</div>
            <div v-text="'Sel.Val.: '+JSON.stringify(selectedValues)+', '+JSON.stringify(selectedValue)" />
            <div>filterText: {{filterText}}</div>
        </template>        
-->

<script lang="ts">

import type {LookupDetailedDisplay, LookupDetailedOption} from "../../implementations/lookup/useLookup";

import {fieldProps, useFieldValueCore} from "../../implementations/forms/useField";
import {computed, defineComponent, ref, watch, nextTick, toRefs, onMounted, onBeforeUnmount} from "vue";
import {lookupProps} from "../../implementations/lookup/useLookup";
import {isBoolean, isDefined, isUndefined, isUndefinedOrNull} from "../../utils/inspect";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useDropdown} from "../../utils/useDropdown";

import AunoaDropdownMenu from "./AunoaDropdownMenu.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";
import AunoaFormLabel from "./AunoaFormLabel.vue";

const UPDATE = "update:modelValue";

const ensureDefined = (value: any) =>
    isDefined(value) ? value : null;

export default defineComponent({
    name: "AunoaSelectBox",
    inheritAttrs: false,
    components: {AunoaDropdownMenu, AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        ...lookupProps,
        searchEnabled: {
            type: Boolean,
            default: false
        },
        displayIcon: {
            type: Boolean,
            default: true
        }
    },
    emits: [UPDATE],
    setup: function (props, {attrs, emit}) {

        const iconClass = ref();
        const iconStyle = ref();

        const option = ref<LookupDetailedOption>();
        const {searchEnabled} = toRefs(props);

        const {locale} = useAunoaI18n();

        const field = useFieldValueCore(<any>props, attrs);
        const {disabled, readonly, formMode} = field;

        const mode = props.searchEnabled ? "typeahead" : "select";
        const dropdown = useDropdown(<any>props, {mode, disabled, readonly});
        const {inputElement, selectedValues, selectedValue, getOption, getFlagIcon, getIcon, getColoredIconStyle, ensureValue} = dropdown;

        watch(selectedValue, value => {
            if (value !== field.value.value) {
                field.value.value = value;
                emit(UPDATE, value);
            }
        });

        watch(field.value, value => {
            value = ensureValue(value);
            value = ensureDefined(value);
            if (value !== selectedValue.value) {
                selectedValues.value = [value];
            }
            //emit(UPDATE, value);
        }, {immediate: true})

        watch([field.value, locale], ([value]) => {
            option.value = !isUndefinedOrNull(value)
                ? getOption(value)
                : undefined;
        }, {immediate: true});

        watch(option, o => {
            iconClass.value = o
                ? getFlagIcon(o) || getIcon(o)
                : undefined;
            iconStyle.value = o
                ? getColoredIconStyle(o)
                : undefined;
        }, {immediate: true});

        //onMounted(()=>{
        //    console.log("onMounted", inputElement.value);
        //    
        //    setTimeout(() =>inputElement.value?.focus(), 100);
        //
        //});
        //onBeforeUnmount(()=>{
        //    console.log("onBeforeUnmount");
        //});

        const inputAttrs = computed(() => ({
            "id": field.name.value,
            "name": field.name.value,
            //"autocomplete": field.name.value,
            "autocomplete": false,
            "placeholder": field.placeholder.value,
            "type": formMode.value === "search" ? "search" : "text",
            "disabled": disabled.value,
            "readonly": readonly.value || !searchEnabled.value,
            "tabindex": searchEnabled.value ? undefined : -1,
            "class": {"typeahead": searchEnabled.value}
        }));

        const onToggle = (e: PointerEvent) => {
            field.value.value = !field.value.value;
            //setTimeout(()=>inputElement.value.focus());
        }

        return {
            ...field,
            ...dropdown,
            iconClass,
            iconStyle,
            inputAttrs,
            onToggle
        }
    }
});

</script>
